










































































































































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';

import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { InactivityWatcher } from '@/inactivityWatcher';
import { getSymbolForCurrency } from '@/utils/coinUtils';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

import { BaseVue } from '../../BaseVue';

@Component({
  components: {
    UiDatePicker2,
    UiSelect2,
    UiTextEdit,
    UiCheckbox,
    UiButton,
    UiDataTable,
  },
})
export default class BalanceReport2 extends BaseVue {
  public date = '';
  public groupBy = 'None';
  public deFiTreatment = 'None';
  public walletId = 'all';
  public emailReport = '';

  public includeIgnored = false;
  public excludeNft = false;
  public skipPricing = false;
  public skipPricingSpam = false;
  public reCheckDeFiSearchTokens = false;
  public exportTokenAddresses = false;
  public returnEmptyBalances = false;

  public currentDay = false;
  public isLoading = false;
  public hasError = false;
  public error? = [];

  public reportData: any = null;
  public dataTableLines: any[] = [];
  public exchangeRates: any[] = [];
  public reportElapsedTime: string | null = null;
  public sortDirection: 'asc' | 'desc' = 'asc';
  public sortColumn = 'ticker';

  public get headers() {
    const h = [
      {
        id: 'ticker',
        label: 'Coin',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'value',
        label: 'Balance',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'fiatValue',
        label: 'Fiat Balance',
        defaultVisibility: true,
        sortable: true,
      },
    ];

    if (this.groupBy === 'Wallet') {
      h.unshift({
        id: 'wallet',
        label: 'Wallet',
        defaultVisibility: true,
        sortable: true,
      });
    }

    return h;
  }

  public sortTable({ asc, id }: { asc: string; id: string }) {
    this.sortColumn = id;
    this.sortDirection = asc ? 'asc' : 'desc';
  }

  public dynamicSort<T>(key: keyof T, order: 'asc' | 'desc' = 'asc'): (a: T, b: T) => number {
    return (a: T, b: T): number => {
      if (a[key] < b[key]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    };
  }

  public get sortedLines() {
    if (!this.sortColumn) return this.dataTableLines;
    const total = this.dataTableLines.pop();
    const lines = this.dataTableLines.sort(this.dynamicSort<any>(this.sortColumn, this.sortDirection));
    lines.push(total);
    return lines;
  }

  public get today() {
    const tzoffset = new Date().getTimezoneOffset() * 60000;
    const localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
    return localISOTime;
  }

  public get missingFeature() {
    // TODO: remove this when we support all filters from the PG database
    return this.checkFeatureFlag('event-sourced-txns') ?? false;
  }

  public get wallets() {
    return this.$store.getters['wallets/WALLETS'];
  }

  public get runReportBtnDisabled() {
    return this.isLoading || !this.date || this.date.trim() === '' || this.groupBy.trim() === '';
  }

  public get overrideRates() {
    const m = this.exchangeRates.filter((m) => m.rate !== m.overrideRate);
    return m;
  }

  public get reportName() {
    return `${this.date.trim()}_bitwave_balance_report.csv`;
  }

  public get fiatSymbol() {
    return getSymbolForCurrency(this.$store.state.currentOrg.baseCurrency);
  }

  public onDateChange() {
    const normalizedToday = this.today.split('T')[0];
    const selected = new Date(this.date);
    const normalizedSelected = new Date(selected).toISOString().split('T')[0];

    if (normalizedToday === normalizedSelected) {
      this.skipPricing = true;
      this.deFiTreatment = 'None';
      this.currentDay = true;
    } else {
      this.currentDay = false;
    }
  }

  public disabledDate(date: Date) {
    return date > new Date(this.today);
  }

  public runReport() {
    this.hasError = false;
    this.error = undefined;
    this.reportElapsedTime = null;

    let overrideExchangeRates;
    if (this.overrideRates.length > 0) {
      overrideExchangeRates = this.overrideRates.map((m) => {
        return {
          currencyId: m.currencyId,
          rate: m.overrideRate,
        };
      });
    }

    const vars: any = {
      orgId: this.$store.state.currentOrg.id,
      reportDetails: {
        title: 'Balance Report as of EOD ' + this.date,
        saveReport: false,
        balanceReport: {
          balanceOnDate: this.date,
          currency: this.$store.state.currentOrg.baseCurrency,
          includeIgnored: this.includeIgnored,
          overrideExchangeRates,
          deFiTreatment: this.deFiTreatment === 'None' ? undefined : this.deFiTreatment?.toLowerCase(),
          emailReport: this.emailReport,
          reCheckDeFi: this.reCheckDeFiSearchTokens,
          excludeNft: this.excludeNft,
          skipPricing: this.skipPricing,
          skipPricingSpam: this.skipPricingSpam,
          exportTokenAddresses: this.exportTokenAddresses,
          returnEmptyBalances: this.returnEmptyBalances,
          priority: this.deFiTreatment === 'Advanced' ? 0 : undefined,
        },
      },
    };

    if (this.groupBy === 'Wallet') {
      vars.reportDetails.balanceReport.groupBy = 'wallet';
    }

    if (this.walletId !== 'all' && this.walletId !== '') {
      vars.reportDetails.walletId = this.walletId;
    }

    this.isLoading = true;

    requestParentToKeepActive('report', true);
    const inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
      () => this.isLoading && document.contains(this.$el)
    );

    const reportStartTime = Date.now();

    this.$apollo
      .mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $reportDetails: ReportDetailsInput!) {
            runReport(orgId: $orgId, reportDetails: $reportDetails) {
              data
            }
          }
        `,
        variables: vars,
      })
      .then((m) => {
        this.reportData = m.data.runReport.data;
        this.dataTableLines = this.reportData?.lines ?? [];
        this.reportElapsedTime = this.getElapsedTime(reportStartTime);

        // insert total lines at the bottom of the balance lines and overwrite ticker with Total
        if (this.reportData.totalFiatValue) {
          this.dataTableLines.push(
            ...this.reportData.totalFiatValue.map((totalLine: any) => {
              return { ...totalLine, ticker: 'Total' };
            })
          );
        }

        if (this.reportData && this.reportData.exchangeRatePointers) {
          this.exchangeRates = this.reportData.exchangeRatePointers.map((m: any) => {
            this.$set(m, 'overrideRate', m.rate);
            this.$set(m, 'editing', false);
            return m;
          });
        }
      })
      .catch((err) => {
        this.error = err.graphQLErrors.map((e: any) => `${e.message} ${e.traceId ? ` - Error ID: ${e.traceId}` : ''}`);
        this.hasError = true;
      })
      .finally(() => {
        this.isLoading = false;
        requestParentToKeepActive('report', false);
        inactivityWatcherKeepActive?.dispose();
      });
  }

  mounted() {
    this.skipPricing = !!this.checkFeatureFlag('no-balance-report-pricing');
    this.skipPricingSpam = !!this.checkFeatureFlag('no-balance-report-pricing');
  }
}
