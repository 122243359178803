<template>
  <v-layout row wrap>
    <v-flex xs10 offset-xs1 class="mb-3 print:tw-flex-1 print:tw-max-w-none print:tw-m-0">
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>{{ $tc('_balanceReport', 2) }}</v-toolbar-title>
          <tooltip-select tip="_completeBalanceReports" class="tw-ml-2"></tooltip-select>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-layout row wrap class="pa-3">
          <v-flex xs9>
            <v-layout row wrap>
              <v-flex xs12>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  full-width
                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                  <!-- https://codepen.io/kzelda/pen/pWOLBp -->
                  <v-text-field slot="activator" label="Balance as of EOD *" v-model="date" readonly></v-text-field>
                  <v-date-picker
                    @change="onChange"
                    v-model="date"
                    no-title
                    scrollable
                    actions
                    :max="today"
                    data-testId="balance-report-date-select"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4 pr-2>
                <v-select
                  pa-2
                  :items="['None', 'Wallet']"
                  :label="$tc('_groupBy') + ' *'"
                  v-model="groupBy"
                  return-object
                  data-testId="balance-report-group-by-select"
                ></v-select>
              </v-flex>
              <v-flex v-if="!currentDay && !missingFeature" xs4 pr-2>
                <v-select
                  :items="['none', 'exclude', 'infer', 'explicit', 'advanced']"
                  label="DeFi Treatment"
                  v-model="deFiTreatment"
                  return-object
                  data-testId="balance-report-wallet-defi-select"
                ></v-select>
              </v-flex>
              <v-flex v-if="currentDay && !missingFeature" xs4 pr-2>
                <v-select
                  disabled
                  :items="['none', 'exclude', 'infer', 'explicit', 'advanced']"
                  label="DeFi Treatment"
                  v-model="deFiTreatment"
                  return-object
                  data-testId="balance-report-defi-select"
                ></v-select>
              </v-flex>
              <v-flex xs4>
                <v-autocomplete
                  v-model="walletId"
                  :items="[{ name: 'All', id: 'all' }, ...wallets]"
                  :label="$t('_ruleWallet')"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  :disabled="this.groupBy !== 'Wallet'"
                  data-testId="balance-report-wallet-select"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field label="Email Report?" v-model="emailReport"></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-checkbox label="Include Ignored Transactions" v-model="includeIgnored" />
              </v-flex>
              <v-flex v-if="!missingFeature" xs4>
                <v-checkbox v-model="excludeNft" :label="$t('_excludeNft')" />
              </v-flex>
              <v-flex xs4 v-if="!currentDay">
                <v-checkbox
                  v-model="skipPricing"
                  :label="$t('_skipPricing')"
                  :disabled="checkFeatureFlag('no-balance-report-pricing')"
                />
              </v-flex>
              <v-flex xs4 v-if="currentDay">
                <v-checkbox v-model="skipPricing" disabled :label="$t('_skipPricing')" />
              </v-flex>
              <v-flex v-if="!missingFeature" xs4>
                <v-checkbox
                  v-model="skipPricingSpam"
                  :label="$t('_skipPricingSpam')"
                  :disabled="checkFeatureFlag('no-balance-report-pricing')"
                />
              </v-flex>
              <v-flex xs4 v-if="deFiTreatment === 'advanced'">
                <v-checkbox label="Check For New Protocols" v-model="reCheckDeFiSearchTokens" />
              </v-flex>
              <v-flex v-if="!missingFeature">
                <v-checkbox label="Export Token Addresses & IDs" v-model="exportTokenAddresses" />
              </v-flex>
              <v-flex>
                <v-checkbox label="Include Empty Balances" v-model="returnEmptyBalances" />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs2 offset-xs1 class="print:tw-hidden">
            <div class="tw-flex">
              <v-btn :disabled="runReportBtnDisabled" @click="runReport" data-testId="balance-report-run-btn">{{
                $tc('_runReport')
              }}</v-btn>
              <tooltip-select tip="_balanceCheck" class="tw-ml-2"></tooltip-select>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-progress-linear indeterminate color="green" :active="isLoading"></v-progress-linear>
          </v-flex>
          <v-flex xs12>
            <v-alert type="warning" :value="hasError">
              <div class="div-grid">
                <div>Problem Generating Report</div>
                <div v-for="(err, index) in error" v-bind:key="`error-${index}`">
                  {{ err }}
                </div>
              </div>
            </v-alert>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xs11 offset-xs1 v-if="reportData" class="print:tw-hidden">
      <v-layout row wrap justify-between>
        <v-flex xs9>
          {{ reportData.blocks }}
        </v-flex>
        <v-flex xs3>
          <download-csv :name="reportName" :data="reportData.lines">
            <v-btn data-testId="balance-report-download-btn">Download CSV</v-btn>
          </download-csv>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs10 offset-xs1 v-if="reportData" class="print:tw-flex-1 print:tw-max-w-none print:tw-m-0">
      <v-data-table class="elevation-5" :hide-actions="true" data-testId="balance-report-table">
        <template slot="no-data"> &nbsp; </template>
        <template slot="headers">
          <tr class="header-row">
            <th></th>
            <th>
              <span v-if="groupBy === 'Wallet'">Wallet</span>
            </th>
            <th>Coin</th>
            <th>Balance</th>
            <th></th>
            <th>Balance USD</th>
          </tr>
          <tr>
            <td class="title" colspan="4">{{ reportData.title }}</td>
            <td class="exchange-rate" colspan="2">
              <span>Exchange Rates</span>
            </td>
          </tr>
          <tr>
            <td colspan="4"></td>
            <td class="exchange-rate" colspan="2">
              <div v-for="(er, index) in exchangeRates" v-bind:key="`rate-${index}`">
                <v-layout>
                  <v-flex xs3> {{ er.displayName }}: </v-flex>
                  <v-flex xs9 v-if="!er.editing">
                    {{ er.overrideRate }}
                    <v-icon @click="er.editing = true" size="small">edit</v-icon>
                  </v-flex>
                  <v-flex xs9 v-else>
                    <v-layout>
                      <v-flex xs7>
                        <v-text-field v-model="er.overrideRate"></v-text-field>
                      </v-flex>
                      <v-flex xs5>
                        <v-icon @click="er.editing = false">checkmark</v-icon>
                        <v-icon @click="cancelEdit(er)">delete</v-icon>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
              <v-layout>
                <v-flex xs12>
                  <v-btn :disabled="overrideRates.length === 0" @click="runReport">Re-run with overrides</v-btn>
                </v-flex>
              </v-layout>
            </td>
          </tr>
          <tr class="input-row" v-for="(item, index) in reportData.lines" v-bind:key="`lines-${index}`">
            <td colspan="2">
              <span v-if="item.wallet !== undefined">{{ item.wallet }}</span>
            </td>
            <td>{{ item.ticker }}</td>
            <td>{{ item.value }}</td>
            <td></td>
            <td>
              <div style="float: left">
                <b> {{ fiatSymbol }} </b>
              </div>
              <div style="float: right">
                <b>{{ item.fiatValue }}</b>
              </div>
            </td>
          </tr>
          <tr class="total-row" v-for="(totalItem, index) in reportData.totalFiatValue" v-bind:key="`fv-${index}`">
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>Total</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <div style="float: left">
                <b>{{ fiatSymbol }}</b>
              </div>
              <div style="float: right">
                <b>{{ totalItem.fiatValue }}</b>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

import { InactivityWatcher } from '@/inactivityWatcher';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

import { getCoinFromEnumString, getSymbolForCurrency } from '../../utils/coinUtils';

export default {
  apollo: {
    $client: 'rptApolloClient',
  },
  data: () => ({
    date: '',
    reportData: null,
    isLoading: false,
    hasError: false,
    groupBy: 'None',
    error: '',
    includeIgnored: false,
    exchangeRates: [],
    deFiTreatment: 'none',
    walletId: 'all',
    emailReport: '',
    reCheckDeFiSearchTokens: false,
    excludeNft: false,
    skipPricing: false,
    skipPricingSpam: false,
    currentDay: false,
    exportTokenAddresses: false,
    returnEmptyBalances: false,
  }),
  computed: {
    overrideRates() {
      const m = this.exchangeRates.filter((m) => m.rate !== m.overrideRate);
      return m;
    },
    runReportBtnDisabled() {
      return this.date.trim() === '' || this.groupBy.trim() === '';
    },
    reportName() {
      return `${this.date.trim()}_bitwave_balance_report.csv`;
    },
    fiatSymbol() {
      return getSymbolForCurrency(this.$store.state.currentOrg.baseCurrency);
    },
    wallets() {
      return this.$store.getters['wallets/WALLETS'];
    },
    today() {
      // doing like this to avoid tz offset issue from toISOString().
      const tzoffset = new Date().getTimezoneOffset() * 60000;
      const localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
      return localISOTime;
    },
    missingFeature() {
      // TODO: remove this when we support all filters from the PG database
      return this.checkFeatureFlag('event-sourced-txns') ?? false;
    },
  },
  methods: {
    cancelEdit(er) {
      er.overrideRatte = null;
      er.editing = false;
    },
    getCoinFromEnumString,
    getSymbolForCurrency,
    runReport() {
      this.hasError = false;
      this.error = undefined;

      let overrideExchangeRates;
      if (this.overrideRates.length > 0) {
        overrideExchangeRates = this.overrideRates.map((m) => {
          return {
            currencyId: m.currencyId,
            rate: m.overrideRate,
          };
        });
      }

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        reportDetails: {
          title: 'Balance Report as of EOD ' + this.date,
          saveReport: false,
          balanceReport: {
            balanceOnDate: this.date,
            currency: this.$store.state.currentOrg.baseCurrency,
            includeIgnored: this.includeIgnored,
            overrideExchangeRates,
            deFiTreatment: this.deFiTreatment === 'none' ? undefined : this.deFiTreatment,
            emailReport: this.emailReport,
            reCheckDeFi: this.reCheckDeFiSearchTokens,
            excludeNft: this.excludeNft,
            skipPricing: this.skipPricing,
            skipPricingSpam: this.skipPricingSpam,
            exportTokenAddresses: this.exportTokenAddresses,
            returnEmptyBalances: this.returnEmptyBalances,
            priority: this.deFiTreatment === 'advanced' ? 0 : undefined,
          },
        },
      };

      if (this.groupBy === 'Wallet') {
        vars.reportDetails.balanceReport.groupBy = 'wallet';
      }

      if (this.walletId !== 'all' && this.walletId !== '') {
        vars.reportDetails.walletId = this.walletId;
      }

      this.isLoading = true;
      requestParentToKeepActive('report', true);
      const inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
        () => this.isLoading && document.contains(this.$el)
      );

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $reportDetails: ReportDetailsInput!) {
              runReport(orgId: $orgId, reportDetails: $reportDetails) {
                data
              }
            }
          `,
          variables: vars,
        })
        .then((m) => {
          this.reportData = m.data.runReport.data;
          console.log(this.reportData);
          if (this.reportData && this.reportData.exchangeRatePointers) {
            this.exchangeRates = this.reportData.exchangeRatePointers.map((m) => {
              this.$set(m, 'overrideRate', m.rate);
              this.$set(m, 'editing', false);
              return m;
            });
          }
        })
        .catch((err) => {
          this.error = err.graphQLErrors.map((e) => `${e.message} ${e.traceId ? ` - Error ID: ${e.traceId}` : ''}`);
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
          requestParentToKeepActive('report', false);
          inactivityWatcherKeepActive?.dispose();
        });
    },
    onChange() {
      const normalizedToday = this.today.split('T')[0];
      const selected = new Date(this.date);
      const normalizedSelected = new Date(selected).toISOString().split('T')[0];
      const timeZoneOffset = selected.getTimezoneOffset();
      if (normalizedToday === normalizedSelected) {
        this.skipPricing = true;
        this.deFiTreatment = 'none';
        this.currentDay = true;
      } else {
        this.currentDay = false;
      }
    },
  },
  mounted() {
    this.skipPricing = !!this.checkFeatureFlag('no-balance-report-pricing');
    this.skipPricingSpam = !!this.checkFeatureFlag('no-balance-report-pricing');
  },
};
</script>
<style scoped>
.header-row {
  text-align: right;
}
.input-row {
  color: blue;
  text-align: right;
}
.total-row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
  text-align: right;
  height: 25px;
}
div.div-grid {
  display: grid;
}
</style>
