






import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import BalanceReport from './BalanceReport.vue';
import BalanceReport2 from './BalanceReport2.vue';

@Component({
  components: {
    BalanceReport,
    BalanceReport2,
  },
})
export default class BalanceReportWrapper extends BaseVue {}
